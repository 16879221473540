import React, { useState, useContext, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import firebase from "gatsby-plugin-firebase"
import publicIp from "public-ip"
import "../css/background-image.css"
import { AuthContext } from "../context/auth"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import esBandera from "../images/idiomas/100espana.svg"
import enBandera from "../images/idiomas/101estados-unidos.svg"
import ptBandera from "../images/idiomas/102brasil.svg"
import { injectIntl, changeLocale, Link, navigate } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core"
import ModalClave from "../components/ModalClave"
import ModalPresencial from "../components/ModalPresencial"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: "none",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: 8,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    padding: 8,
  },
}))

const IndexPage = ({ props, intl, data }) => {
  const { user, setUser } = useContext(AuthContext)
  const classes = useStyles()
  const {
    nombre,
    imagen_fondo,
    bienvenida,
    registro,
    modal_recuperar_clave,
    ingresar,
    recordarme,
    clave,
    color_botones,
    color_letras,
    color_fondo,
    ingreso_habilitado,
    alert_ingreso_habilitado,
    alert_verificar_email,
    error_usuario_no_existe,
    error_password,
    error_email_mal_escrito,
  } = data.strapiLogin
  const { usuarios_habilitados } = data.configuracion
  useEffect(() => {
    if (user && ingreso_habilitado) {
      /*       if (user.emailVerified) { */
      navigate("/inicio")
      //   }
    }
  }, [user])

  const [datos, setDatos] = useState({
    email: "",
    password: "",
    error: null,
  })

  // para que no hagan log de 2 ip diferentes
  const getClientIp = async () =>
    await publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    })
  const [ip, setIp] = useState("")
  useEffect(() => {
    getClientIp().then(result => setIp(result))
  }, [])

  const grabarIp = props => {
    firebase.firestore().collection("usuarios").doc(props.uid).update({
      ip: ip,
      ultimo_login: Date.now().toString(),
    })
  }
  // para que no hagan log de 2 ip diferentes

  const handleChange = e => {
    setDatos({ ...datos, [e.target.name]: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setDatos({ ...datos, error: null })

    if (ingreso_habilitado || usuarioHabilitado(datos.email.trim())) {
      firebase
        .auth()
        .signInWithEmailAndPassword(datos.email.trim(), datos.password.trim())
        .then(result2 => {
          //  if (result2.user.emailVerified) {
          setUser(result2.user)
          grabarIp(result2.user)
          navigate("/inicio")
          //   } else {
          // alert(
          //   currentLocale === "en"
          //     ? alert_verificar_email.en
          //     : currentLocale === "pt"
          //     ? alert_verificar_email.pt
          //     : alert_verificar_email.es
          // )
          // firebase.auth().signOut()
          //  }
        })
        .catch(function (error) {
          error.message ===
          "There is no user record corresponding to this identifier. The user may have been deleted."
            ? setDatos({
                ...datos,
                error:
                  currentLocale === "en"
                    ? error_usuario_no_existe.en
                    : currentLocale === "pt"
                    ? error_usuario_no_existe.pt
                    : error_usuario_no_existe.es,
              })
            : error.message ===
              "The password is invalid or the user does not have a password."
            ? setDatos({
                ...datos,
                error:
                  currentLocale === "en"
                    ? error_password.en
                    : currentLocale === "pt"
                    ? error_password.pt
                    : error_password.es,
              })
            : error.message === "The email address is badly formatted."
            ? setDatos({
                ...datos,
                error:
                  currentLocale === "en"
                    ? error_email_mal_escrito.en
                    : currentLocale === "pt"
                    ? error_email_mal_escrito.pt
                    : error_email_mal_escrito.es,
              })
            : setDatos({ ...datos, error: error.message })
        })
    } else {
      alert(
        currentLocale === "en"
          ? alert_ingreso_habilitado.en
          : currentLocale === "pt"
          ? alert_ingreso_habilitado.pt
          : alert_ingreso_habilitado.es
      )
    }
  }

  const currentLocale = intl.locale

  const image = getImage(imagen_fondo.localFile)
  const bgImage = convertToBgImage(image)

  const usuarioHabilitado = usuario => {
    let habilitado = false
    usuarios_habilitados.forEach(function (revisar) {
      if (revisar.email === usuario && revisar.ingreso) {
        //
        habilitado = true
      }
    })
    return habilitado
  }

  return (
    <>
      <Layout>
        <SEO
          title={
            currentLocale === "en"
              ? nombre.en
              : currentLocale === "pt"
              ? nombre.pt
              : nombre.es
          }
        />
        <BackgroundImage
          Tag="section"
          {...bgImage}
          preserveStackingContext
          style={{ position: "fixed", top: 0 }}
          className="backimage"
        >
          <Container
            component="main"
            maxWidth="xs"
            style={{ backgroundColor: `${color_fondo}` }}
          >
      
            <CssBaseline />
            <div className={classes.paper}>
              <Typography
                component="h6"
                variant="h6"
                // style={{ fontSize: `1.25vw` }}
                style={{ fontSize: `${bienvenida.fuente_size}vw` }}
              >
                {currentLocale === "en"
                  ? bienvenida.en
                  : currentLocale === "pt"
                  ? bienvenida.pt
                  : bienvenida.es}
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <ModalPresencial
                  modal_recuperar_clave={modal_recuperar_clave}
                />

                <Grid container>
                  <Grid item xs>
                    <Link to={"/registro/"} className={classes.link}>
                      <Button
                        fullWidth
                        variant="contained"
                        style={{
                          background: `${color_botones}`,
                          color: `${color_letras}`,
                        }}
                        className={classes.submit}
                      >
                        {currentLocale === "en"
                          ? registro.en
                          : currentLocale === "pt"
                          ? registro.pt
                          : registro.es}
                      </Button>
                    </Link>
                  </Grid>
                </Grid>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-Mail"
                  name="email"
                  autoComplete="email"
                  //  autoFocus
                  value={datos.email}
                  onChange={handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={
                    currentLocale === "en"
                      ? clave.en
                      : currentLocale === "pt"
                      ? clave.pt
                      : clave.es
                  }
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={datos.password}
                  onChange={handleChange}
                />
    {/*             <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={
                    currentLocale === "en"
                      ? recordarme.en
                      : currentLocale === "pt"
                      ? recordarme.pt
                      : recordarme.es
                  }
                /> */}

                {datos.error ? (
                  <p style={{ color: "red" }}>{datos.error}</p>
                ) : null}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{
                    background: `${color_botones}`,
                    color: `${color_letras}`,
                  }}
                  className={classes.submit}
                >
                  {currentLocale === "en"
                    ? ingresar.en
                    : currentLocale === "pt"
                    ? ingresar.pt
                    : ingresar.es}
                </Button>
                <ModalClave modal_recuperar_clave={modal_recuperar_clave} />
              </form>
            </div>

            <div>
              {currentLocale === "es" ? (
                <div>
                  <Button type="button">
                    <img
                      width={40}
                      //  style={{ margin: 20 }}
                      src={enBandera}
                      alt="en"
                      onClick={() => changeLocale("en")}
                    />
                  </Button>
                  <Button type="button">
                    <img
                      width={40}
                      //  style={{ margin: 20 }}
                      src={ptBandera}
                      alt="pt"
                      onClick={() => changeLocale("pt")}
                    />
                  </Button>
                </div>
              ) : currentLocale === "pt" ? (
                <div>
                  <Button type="button">
                    <img
                      width={40}
                      //     style={{ margin: 20 }}
                      src={enBandera}
                      alt="en"
                      onClick={() => changeLocale("en")}
                    />
                  </Button>
                  <Button type="button">
                    <img
                      width={40}
                      //    style={{ margin: 20 }}
                      src={esBandera}
                      alt="es"
                      onClick={() => changeLocale("es")}
                    />
                  </Button>
                </div>
              ) : (
                <div>
                  <Button type="button">
                    <img
                      width={40}
                      //  style={{ margin: 20 }}
                      src={esBandera}
                      alt="es"
                      onClick={() => changeLocale("es")}
                    />
                  </Button>
                  <Button type="button">
                    <img
                      width={40}
                      //  style={{ margin: 20 }}
                      src={ptBandera}
                      alt="pt"
                      onClick={() => changeLocale("pt")}
                    />
                  </Button>
                </div>
              )}
            </div>
          </Container>
        </BackgroundImage>
      </Layout>
    </>
  )
}

export default injectIntl(IndexPage)

export const query = graphql`
  query {
    configuracion: strapiConfiguracion {
      usuarios_habilitados {
        email
        ingreso
      }
    }
    strapiLogin {
      ingreso_habilitado
      alert_ingreso_habilitado {
        es
        en
        pt
      }
      alert_verificar_email {
        es
        en
        pt
      }
      error_usuario_no_existe {
        es
        en
        pt
      }
      error_password {
        es
        en
        pt
      }
      error_email_mal_escrito {
        es
        en
        pt
      }
      color_botones
      color_letras
      color_fondo
      nombre {
        es
        en
        pt
      }
      bienvenida {
        es
        en
        pt
        fuente_size
      }
      clave {
        es
        en
        pt
      }
      recordarme {
        es
        en
        pt
      }
      ingresar {
        es
        en
        pt
      }
      modal_recuperar_clave {
        titulo {
          es
          en
          pt
          fuente_size
        }
        boton_recuperar_clave {
          es
          en
          pt
          color_letra
          color_fondo
        }
        boton_enviar_correo {
          es
          en
          pt
          color_letra
          color_fondo
        }
        alert_correo_enviado {
          es
          en
          pt
        }
        alert_correo_no_existe {
          es
          en
          pt
        }
      }
      registro {
        es
        en
        pt
      }
      imagen_fondo {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
