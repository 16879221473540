import React, { useState } from "react"
import {
  Modal,
  Button,
  CssBaseline,
  TextField,
  Typography,
  makeStyles,
  // Container,
} from "@material-ui/core"
import { injectIntl } from "gatsby-plugin-intl"
import firebase from "gatsby-plugin-firebase"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    left: "10%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  boton: {
    position: "absolute",
    left: "1%",
    bottom: "20%",
  },
}))

const ModalClave = ({ intl, modal_recuperar_clave }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const currentLocale = intl.locale
  const {
    titulo,
    boton_recuperar_clave,
    boton_enviar_correo,
    alert_correo_enviado,
    alert_correo_no_existe,
  } = modal_recuperar_clave
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [data, setData] = useState({
    email: "",
  })

  const handleChange = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    firebase
      .auth()
      .sendPasswordResetEmail(data.email)
      .then(function () {
        // Email sent.
        setOpen(false)
        alert(currentLocale === "en"
        ? alert_correo_enviado.en
        : currentLocale === "pt"
        ? alert_correo_enviado.pt
        : alert_correo_enviado.es)
      })
      .catch(function (error) {
        // An error happened.
        setOpen(false)
        alert(currentLocale === "en"
        ? alert_correo_no_existe.en
        : currentLocale === "pt"
        ? alert_correo_no_existe.pt
        : alert_correo_no_existe.es)
      })
  }

  const body = (
    <div className={classes.paper}>
      <CssBaseline />
      <Typography
        component="h6"
        variant="h6"
        style={{ fontSize: `${titulo.fuente_size}vw` }}
      >
        {currentLocale === "en"
          ? titulo.en
          : currentLocale === "pt"
          ? titulo.pt
          : titulo.es}
      </Typography>
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="E-Mail"
          name="email"
          autoComplete="email"
          //   autoFocus
          value={data.email}
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          style={{
            background: `${boton_enviar_correo.color_fondo}`,
            color: `${boton_enviar_correo.color_letra}`,
            marginTop: 20,
          }}
        >
          {currentLocale === "en"
            ? boton_enviar_correo.en
            : currentLocale === "pt"
            ? boton_enviar_correo.pt
            : boton_enviar_correo.es}
        </Button>
      </form>
    </div>
  )

  return (
    <div>
      <Button
        fullWidth
        variant="contained"
        style={{
          background: `${boton_recuperar_clave.color_fondo}`,
          color: `${boton_recuperar_clave.color_letra}`,
        }}
        onClick={handleOpen}
      >
        {currentLocale === "en"
          ? boton_recuperar_clave.en
          : currentLocale === "pt"
          ? boton_recuperar_clave.pt
          : boton_recuperar_clave.es}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}

export default injectIntl(ModalClave)
