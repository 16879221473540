import React, { useState } from "react"
import {
  Modal,
  Button,
  CssBaseline,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { injectIntl } from "gatsby-plugin-intl"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    left: "10%",
    height: "90%",
    top: "5%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  boton: {
    position: "absolute",
    left: "1%",
    bottom: "20%",
  },
  iframevideo: {
    position: "absolute",
    top: "0%",
    left: "0%",
    width: "100%",
    minHeight: "100%",
    border: 0,
    backgroundColor: "#7769927a",
    padding: 15,
    borderRadius: 1,
  },
}))

const ModalPresencial = ({ intl, modal_recuperar_clave }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { boton_recuperar_clave } = modal_recuperar_clave
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const body = (
    <div className={classes.paper}>
      <iframe
        className={classes.iframevideo}
        src={"https://inscripcion.industria40arg.com.ar/"}
        title="Registro presencial"
        //  allow="accelerometer; autoPlay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        allow="autoplay"
      />
    </div>
  )

  return (
    <div>
      <Button
        fullWidth
        variant="contained"
        style={{
          background: `${boton_recuperar_clave.color_fondo}`,
          color: `${boton_recuperar_clave.color_letra}`,
        }}
        onClick={handleOpen}
      >
        INSCRIPCIÓN PRESENCIAL
        {/* {currentLocale === "en"
          ? boton_recuperar_clave.en
          : currentLocale === "pt"
          ? boton_recuperar_clave.pt
          : boton_recuperar_clave.es} */}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}

export default injectIntl(ModalPresencial)
